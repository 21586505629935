/* eslint-disable import/prefer-default-export */
export enum RequestKeys {
    getContractBasisAdditionalFee = 'getContractBasisAdditionalFee',
    getContractBasisAdditionalFeeDetails = 'getContractBasisAdditionalFeeDetails',
    getContractBasisLaneFeeDetails = 'getContractBasisLaneFeeDetails',
    getContractBasisLaneGroups = 'getContractBasisLaneGroups',
    getContractBasisLaneGroupsDetails = 'getContractBasisLaneGroupsDetails',
    getContractBasisLaneGroupsDetailsCopy = 'getContractBasisLaneGroupsDetailsCopy',
    getContractBasisLaneGroupLaneDetails = 'getContractBasisLaneGroupLaneDetails',
    getContractBasisLaneGroupLaneDetailsCopy = 'getContractBasisLaneGroupLaneDetailsCopy',
    getContryForStateSelector = 'getContryForStateSelector',
    getSelectedLaneForNewCustomerTransport = 'getSelectedLaneForNewCustomerTransport',
    getOrderNotifications = 'getOrderNotifications',
    getNotificationEmail = 'getNotificationEmail',
    getProductTypesContractBasis = 'getProductTypesContractBasis',
    getProductTypesTransportSimulation = 'getProductTypesTransportSimulation',
    rejectContainers = 'rejectContainers',
    rebookContainers = 'rebookContainers',
    getContractbasisQuotePreview = 'getContractbasisQuotePreview',
    getContainerTypes = 'getContainerTypes',
    getContainerType = 'getContainerType',
    getContainerBySerialNumber = 'getContainerBySerialNumber',
    getBillingInformation = 'getBillingInformation',
    getLaneFees = 'getLaneFees',
    getContainerLastGeolocation = 'getContainerLastGeolocation',
    getAdditionalFees = 'getAdditionalFees',
    getAllowedProductTypes = 'getAllowedProductTypes',
    getAllowedLaneFeesFeeNameToChargeAbilityMapping = 'getAllowedLaneFeesFeeNameToChargeAbilityMapping',
    getAllowedAdditionalFeesFeeNameToChargeAbilityMapping = 'getAllowedAdditionalFeesFeeNameToChargeAbilityMapping',
    getLogger = 'getLogger',
    getLoggerByLoggerNumber = 'getLoggerByLoggerNumber',
    getLocation = 'getLocation',
    getLocations = 'getLocations',
    getAccount = 'getAccount',
    getAccountLane = 'getAccountLane',
    getAccountLanesByOpportunities = 'getAccountLanesByOpportunities',
    getAccountQuote = 'getAccountQuote',
    getLanesToBeGroupedByAccountQuote = 'getLanesToBeGroupedByAccountQuote',
    getLanesToAwardRejectByAccountQuote = 'getLanesToAwardRejectByAccountQuote',
    getLaneFeesToAwardRejectByAccountQuote = 'getLaneFeesToAwardRejectByAccountQuote',
    getLaneGroupsPricingAssessment = 'getLaneGroupsPricingAssessment',
    getLaneGroupPricingAssessmentById = 'getLaneGroupPricingAssessmentById',
    getGateway = 'getGateway',
    getForecastSubmissionData = 'getForecastSubmissionData',
    getLocationContacts = 'getLocationContacts',
    getBillingCompany = 'getBillingCompany',
    getCompany = 'getCompany',
    'getLaneGroupSubEntities-LaneFees' = 'getLaneGroupSubEntitiesLaneFees',
    'getLaneGroupSubEntities-Lane' = 'getLaneGroupSubEntitiesLane',
    getAirportsFilteredByLaneGroupIds = 'getAirportsFilteredByLaneGroupIds',
    getStepConfiguration = 'getStepConfiguration',
    getContractBasisProductType = 'getContractBasisProductType',
    getFxRates = 'getFxRates',
    getContainerNotes = 'getContainerNotes',
    getContainerNoteDetails = 'getContainerNoteDetails',
    getFxRateById = 'getFxRateById',
    getSensorData = 'getSensorData',
    getContactByLocation = 'getContactByLocation',
    getContactByIds = 'getContactByIds',
    getCountry = 'getCountry',
    getContact = 'getContact',
    getLaneFeeSelector = 'getLaneFeeSelector',
    getAdditionalFeeSelector = 'getAdditionalFeeSelector',
    getMainCategory = 'getMainCategory',
    getCustomerExecution = 'getCustomerExecution',
    getSharePositioningList = 'getSharePositioningList',
    getSharePositioning = 'getSharePositioning',
    getContainer = 'getContainer',
    getDamage = 'getDamage',
    getDamageModifications = 'getDamageModifications',
    getAttachment = 'getAttachment',
    getContractBasis = 'getContractBasis',
    getLoggerContainerInformation = 'getLoggerContainerInformation',
    getLaneFeesByLaneGroup = 'getLaneFeesByLaneGroup',
    getPositionsByInvoiceId = 'getPositionsByInvoiceId',
    getLaneByLaneGroup = 'getLaneByLaneGroup',
    getOrderCountForLaneFee = 'getOrderCountForLaneFee',
    getOrderCountForAdditionalFee = 'getOrderCountForAdditionalFee',
    getLocationById = 'getLocationById',
    getLaneOpsInformationById = 'getLaneOpsInformationById',
    getUser = 'getUser',
    getUsers = 'getUsers',
    getRegionalGroupById = 'getRegionalGroupById',
    getGeneralPricingDetails = 'getGeneralPricingDetails',
    getRegionalGroupsByAccount = 'getRegionalGroupsByAccount',
    getGeneralPricingDetailsByContractId = 'getGeneralPricingDetailsByContractId',
    getAvailableLanes = 'getAvailableLanes',
    getLocationAreaDetailsByLocationId = 'getLocationAreaDetailsByLocationId',
    getLocationArea = 'getLocationArea',
    getAlLeasePricing = 'getAlLeasePricing',
    getAlLogisticPricing = 'getAlLogisticPricing',
    getDamageByContainer = 'getDamageByContainer',
    getOpportunityById = 'getOpportunityById',
    getOpportunities = 'getOpportunities',
    getDeletedLoggerHistory = 'getDeletedLoggerHistory',
    getLaneGroupModifications = 'getLaneGroupModifications',
    getOpportunityModifications = 'getOpportunityModifications',
    getLocationFilterRequest = 'getLocationFilterRequest',
    getLoggerTypeDetails = 'getLoggerTypeDetails',
    getTemperatureSimulation = 'getTemperatureSimulation',
    getLaneById = 'getLaneById',
    getLanes = 'getLanes',
    getGatewayModifications = 'getGatewayModifications',
    getOpportunitiesForRegionalGroups = 'getOpportunitiesForRegionalGroups',
    getOpportunitiesByAccount = 'getOpportunitiesByAccount',
    getCo2Calculation = 'getCo2Calculation',
    getPositionTypeMapping = 'getPositionTypeMapping',
    getAvailableLanesForCTCreation = 'getAvailableLanesForCTCreation',
    getPreviousOrdersByOrder = 'getPreviousOrdersByOrder',
    getUserSelector = 'getUserSelector',
    getLinkedOpportunity = 'getLinkedOpportunity',
    getSimulationById = 'getSimulationById',
    getRightsByName = 'getRightsByName',
    getLanesFromOpportunity = 'getLanesFromOpportunity',
    getOrderByNumber = 'getOrderByNumber',
    getQuoteDocuments = 'getQuoteDocuments',
    checkAwardedLanes = 'checkAwardedLanes',
    getLoggerDocuments = 'getLoggerDocuments',
    getAccounts = 'getAccounts',
    getOpportunitiesAll = 'getOpportunitiesAll',
    getAccountOptions = 'getAccountOptions',
    getLanesByQuote = 'getLanesByQuote',
    getCompanyParentSubsidiaries = 'getCompanyParentSubsidiaries',
    getPositioningSetup = 'getPositioningSetup',
    getOrderComments = 'getOrderComments',
    hasUnreadAlerts = 'hasUnreadAlerts',
    getOpportunitiesByQuote = 'getOpportunitiesByQuote',
    getContactsByIds = 'getContactsByIds',
    getAllAlertsNotifications = 'getAllAlertsNotifications',
    getForecastById = 'getForecastById',
    getInternalOrderOptions = 'getInternalOrderOptions',
    getPopulationOrderStep = 'getPopulationOrderStep',
    getLoggerModifications = 'getLoggerModifications',
    getActivityRecord = 'getActivityRecord',
    getPreBookings = 'getPreBookings',
    getPreBookingAvailableLanes = 'getPreBookingAvailableLanes',
    getPreBooking = 'getPreBooking',
    getPreBookingByParams = 'getPreBookingByParams',
    getPreBookingComments = 'getPreBookingComments',
    getLaneNotificationsSettings = 'getLaneNotificationsSettings',
    getPreBookingModifications = 'getPreBookingModifications',
    getPreBookingRequiredContainersChanges = 'getPreBookingRequiredContainersChanges',
    getAttachments = 'getAttachments',
    getEngagement = 'getEngagement',
    getEngagementByContactId = 'getEngagementByContactId',
    getContractBasisHasValidAdditionalFees = 'getContractBasisHasValidAdditionalFees',
    getCountries = 'getCountries',
    getAutoPopulatePosition = 'getAutoPopulatePosition',
    getCurrentMonthForecast = 'getCurrentMonthForecast',
    getLaneRequestedAwardedProductTypes = 'getLaneRequestedAwardedProductTypes',
    getServiceCenterById = 'getServiceCenterById',
    getAvailability = 'getAvailability',
}
