import React, {
    useCallback,
    useMemo,
} from 'react'
import ReactApexChart from 'react-apexcharts'

import {
    renderToString,
} from 'react-dom/server'
import {
    Availability,
} from '../../../serviceCenters.types'
import getOptions from './optionsChart'
import CustomTooltip from './CustomTooltip'

const ContainerAvailabilityForecastChart = ({
    value,
    listStatuses,
    containerStatus,
    minPreconditioned,
    l,
}: {
    value: Availability[],
    listStatuses: string[],
    containerStatus: Record<string, boolean>,
    minPreconditioned: number,
    l: (l:string) => string,
}) => {
    const categories: string[] = useMemo(() => {
        return value?.map((item) => {
            return item?.date
        })
    }, [value])

    const custom = useCallback(({
        series: selectedSeries,
        dataPointIndex,
        w,
    }: {
        series: number[][],
        dataPointIndex: number,
        w: {
            globals :{
                labels: string[],
            }
        }
    }) => {
        return renderToString(
            <CustomTooltip
                date={w.globals.labels[dataPointIndex]}
                minPreconditioned={minPreconditioned}
                processed={selectedSeries[0][dataPointIndex]}
                process={selectedSeries[1][dataPointIndex]}
                preconditioned={selectedSeries[2][dataPointIndex]}
            />,
        )
    }, [minPreconditioned])

    const series = useMemo(() => {
        return value
            ?.reduce(
                (acc: {name: string, data: number[]}[], availability) => {
                    if (!acc.length) {
                        return listStatuses
                            .filter((statusName) => {
                                return containerStatus[statusName]
                            })
                            .map((statusName) => {
                                return {
                                    name: statusName,
                                    data: [availability[statusName]],
                                }
                            })
                    }

                    return acc.map((el) => {
                        return {
                            ...el,
                            data: [
                                ...el.data,
                                availability[el.name],
                            ],
                        }
                    })
                }, [],
            )
            .map((item) => {
                return {
                    name: l(`Monitoring.${[item.name]}`),
                    data: item.data,
                }
            })
    }, [
        containerStatus,
        l,
        listStatuses,
        value,
    ])

    const options = useMemo(() => {
        return getOptions({
            categories,
            minPreconditioned,
            custom,
        })
    }, [
        categories,
        custom,
        minPreconditioned,
    ])

    return (
        <ReactApexChart
            options={options}
            height={700}
            style={{
                width: '100%',
            }}
            series={series || []}
            type="bar"
        />
    )
}

export default ContainerAvailabilityForecastChart
