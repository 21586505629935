import React from 'react'

import ContainerAvailabilityForecast from './ ContainerAvailabilityForecast'

const Monitoring = ({
    id,
}: {
    id: number
}) => {
    return (
        <ContainerAvailabilityForecast id={id} />
    )
}

export default Monitoring
