import {
    useEffect, useMemo,
} from 'react'
import useRequestTable from 'app/hooks/useRequestTable'
import isEmpty from 'lodash/isEmpty'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/PreBooking/PreBookings.requests'
import {
    FilterOptions,
} from 'app/hooks/useFilter'
import useFirstLoading from 'app/hooks/useFirstLoading'
import {
    PreBooking,
} from 'app/Apps/PreBooking/PreBooking.types'
import {
    TableResponse,
} from 'app/types/request.types'

export default ({
    filterOptions, dataAdapter,
}:{
    filterOptions?: FilterOptions,
    dataAdapter?: (d: Record<string, any>[]) => Record<string, any>[],
}) => {
    const {
        isDataReady,
        refetch,
        data: response,
        isError,
        error,
        isFetching,
    } = useRequestTable<TableResponse<PreBooking[]>>({
        key: RequestKeys.getPreBookings,
        params: {
            includeFilters: isEmpty(filterOptions?.filter) ? undefined : filterOptions?.filter,
        },
        config: requests.filterRequest,
        keepPreviousData: true,
        // enabled: true,
        enabled: !isEmpty(filterOptions?.filter),
    })

    const data = useMemo(() => {
        if (response?.data) {
            const responseData = response?.data as PreBooking[]

            return dataAdapter ? dataAdapter(responseData) : responseData
        }
        return []
    }, [
        dataAdapter,
        response?.data,
    ])

    useEffect(() => {
        if (isDataReady) {
            filterOptions?.setFilteredData(data)
        }
    }, [
        data,
        filterOptions,
        isDataReady,
    ])

    const loaded = useFirstLoading(isFetching)

    return useMemo(() => {
        return {
            isError,
            loaded,
            error,
            isDataReady,
            refetch,
            data,
        }
    }, [
        data,
        error,
        isDataReady,
        isError,
        loaded,
        refetch,
    ])
}
