import React, {
    useCallback,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import StatusHandler from 'app/shared-components/StatusHandler'
import useFilter from 'app/hooks/useFilter'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    getEditedItems,
} from 'app/shared-components/SkyNetSpreadSheet'
import useGetOpportunities from '../hooks/useGetOpportunities'
import OpportunitiesSpreadSheet from '../OpportunitiesSpreadSheet'
import useOpportunitiesBulkEdit from './hooks/useOpportunitiesBulkEdit'
import {
    OpportunityType,
} from '../opportunities.types'

const OpportunitiesBulkEdit = ({
    backUrl,
}: {
    backUrl: string
}) => {
    const filterOptions = useFilter()

    const {
        isError,
        loaded,
        error,
    } = useGetOpportunities(filterOptions)

    const history = useHistory()

    const onSuccess = useCallback(() => {
        history.push(backUrl)
    }, [
        history,
        backUrl,
    ])

    const opportunitiesEdit = useOpportunitiesBulkEdit()

    const onSave = useCallback((
        opportunities: OpportunityType[],
        options?: AsyncMutationOptions,
    ) => {
        const editedItems = getEditedItems({
            allItems: opportunities,
            initData: filterOptions.filteredData,
        })

        opportunitiesEdit(editedItems, {
            ...options,
            onSuccess: options?.onSuccess || onSuccess,
        })
    }, [
        filterOptions?.filteredData,
        onSuccess,
        opportunitiesEdit,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <OpportunitiesSpreadSheet
                onSave={onSave}
                onCancel={onSuccess}
                filterOptions={filterOptions}
            />
        </StatusHandler>

    )
}

export default OpportunitiesBulkEdit
