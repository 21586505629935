import React from 'react'

import useLabel from 'app/hooks/useLabel'

import useStyles from './CustomTooltip.styles'

const CustomTooltip = ({
    date,
    minPreconditioned,
    processed,
    process,
    preconditioned,
}: {
    date: string,
    minPreconditioned: number,
    processed: number,
    process: number,
    preconditioned: number,
}) => {
    const {
        classes,
        cx,
    } = useStyles()

    const l = useLabel()

    return (
        <div className={classes.tooltipWrapper}>
            <div className={classes.wrapper}>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.date')}</div>
                    <div>{date}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.total')}</div>
                    <div>{processed + process + preconditioned}</div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.minPreconditioned')}</div>
                    <div>{minPreconditioned}</div>
                </div>
            </div>
            <div className={classes.wrapper}>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.toBeProcessed')}</div>
                    <div className={classes.valueWrapper}>
                        <div className={cx(classes.marker, classes.processed)} />
                        <div className={classes.value}>{processed}</div>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.inProcess')}</div>
                    <div className={classes.valueWrapper}>
                        <div className={cx(classes.marker, classes.process)} />
                        <div className={classes.value}>{process}</div>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.label}>{l('Monitoring.preconditioned')}</div>
                    <div className={classes.valueWrapper}>
                        <div className={cx(classes.marker, classes.preconditioned)} />
                        <div className={classes.value}>{preconditioned}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomTooltip
