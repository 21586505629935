const getOptions = ({
    categories,
    minPreconditioned,
    custom,
}: {
    categories: string[],
    minPreconditioned: number,
    custom: (args: any) => string,
}) => {
    return {
        legend: {
            horizontalAlign: 'left',
        } as ApexLegend,
        tooltip: {
            custom,
        },
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: true,
                offsetX: -60,
                offsetY: 0,
                tools: {
                    download: '<img src="/assets/images/download_button.svg" width="24px" height="24px" alt="download"/>',
                },
            },
        },
        xaxis: {
            categories,
        },
        colors: [
            '#EDAE49',
            '#8BD1E8',
            '#57B1CF',
        ],
        dataLabels: {
            enabled: false,
        },
        grid: {
            padding: {
                right: 70,
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
            strokeDashArray: 7,
        },
        annotations: {
            yaxis: [{
                y: minPreconditioned,
                strokeDashArray: 10,
                borderColor: '#00739A',
                label: {
                    style: {
                        fontSize: '15px',
                        color: '#00739A',
                        background: 'none',
                    },
                    offsetX: 70,
                    textAnchor: 'end',
                    borderWidth: 0,
                    text: 'Min Precon',
                },
            }],
        },
    }
}

export default getOptions
