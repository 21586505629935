import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        cardContent: {
            height: 820,
        },
        panel: {
            display: 'flex',
        },
        containerStatus: {
            flexBasis: 400,
        },
        productType: {
            flexBasis: 200,
        },
        wrapperCheckbox: {
            display: 'flex',
        },
        checkbox: {
            padding: '0 !important',
        },
        tooltipWrapper: {
            width: 365,
            height: 140,
            padding: theme.spacing(2),
            display: 'flex',
        },
    }
})

export default useStyles
