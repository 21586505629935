import queryString from 'query-string'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    PreBookingEntity,
    PreBookingReasonForChange,
} from './PreBooking.types'

type PreBookingRequests = {
    domainName: string,
    filterRequest: (params) => RequestConfig,
    get: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    bulkCreate: ({
        data,
    }: {
        data: PreBookingEntity[]
    }) => RequestConfig,
    bulkEdit: ({
        data,
    }: {
        data: PreBookingEntity[]
    }) => RequestConfig,
    getPrebookingByParams: ({
        customerReference,
        mawb,
    }: {
        customerReference?: string,
        mawb?: string,
    }) => RequestConfig,
    getAvailableLanes: (param) => RequestConfig,
    getAvailableLanesFilter: () => {
        customUrl: string
    },
    create: ({
        data,
    }: {
        data: PreBookingEntity
    }) => RequestConfig,
    update: ({
        id,
        data,
        reason,
    }: {
        id: number,
        data: PreBookingEntity,
        reason: PreBookingReasonForChange,
    }) => RequestConfig,
    modifications: ({
        id,
    }: {
        id: number
    }) => RequestConfig,
    getRequiredContainersChanges: ({
        id,
    }: {
        id: number
    }) => RequestConfig,
}

const preBookingRequests: PreBookingRequests = {
    domainName: 'preBooking',
    filterRequest: (params) => {
        return {
            url: 'prebooking/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `prebooking/${id}`,
            method: 'GET',
        }
    },
    bulkCreate: ({
        data,
    }) => {
        return {
            url: 'prebooking/bulk',
            method: 'POST',
            data,
        }
    },
    bulkEdit: ({
        data,
    }) => {
        return {
            url: 'prebooking/data/bulk',
            method: 'PATCH',
            data,
        }
    },
    getPrebookingByParams: ({
        customerReference,
        mawb,
    }) => {
        return {
            url: queryString.stringifyUrl({
                url: 'prebooking',
                query: {
                    customerReference,
                    mawb,
                },
            }),
            method: 'GET',
        }
    },
    getAvailableLanes: ({
        params,
    }) => {
        return {
            url: 'lane/filter-request/cs-overview',
            method: 'POST',
            ...params,
        }
    },
    getAvailableLanesFilter: () => {
        return {
            customUrl: 'lane/filter-request/cs-overview',
        }
    },
    create: ({
        data,
    }) => {
        return {
            url: 'prebooking',
            method: 'POST',
            data,
        }
    },
    update: ({
        id,
        data,
        reason,
    }) => {
        return {
            url: `prebooking/${id}?modificationReason=${reason}`,
            method: 'PUT',
            data,
        }
    },
    modifications: ({
        id,
    }) => {
        return {
            url: `prebooking/${id}/modifications`,
            method: 'GET',
        }
    },
    getRequiredContainersChanges: ({
        id,
    }) => {
        return {
            url: `prebooking/${id}/requiredcontainerschanges/all`,
            method: 'GET',
        }
    },
}

export default preBookingRequests
